<template>
  <v-card class="text-center pb-4" width="100%">
    <v-card-title
      class="justify-center text--secondary font-weight-regular text-uppercase"
      >Pourcentage cas COVID-19
    </v-card-title>
    <v-card-text class="justify-center">
      <h1 class="primary--text">{{ percentOfPec }} %</h1>
    </v-card-text>
  </v-card>
</template>

<script>
import FormulaireService from '../../../services/formulaire.service'

export default {
  name: 'PercentCovidCard',

  data: () => ({
    pecNumber: 0,
    totNumber: 1
  }),

  mounted() {
    // Récupréation du total
    FormulaireService.countPatientsInfo().then(
      response => {
        this.totNumber = response.data.patientsNumber
      },
      error => {
        console.error(error.response)
      }
    )

    // Récupération du nombre de cas covid
    FormulaireService.getCovidCount().then(
      response => {
        this.pecNumber = response.data[0].finess
      },
      error => {
        console.error(error.response)
      }
    )
  },

  computed: {
    percentOfPec() {
      return this.totNumber != 1 ? ((this.pecNumber * 100) / this.totNumber).toFixed(1) : 0
    }
  }
}
</script>
